/* eslint-disable prettier/prettier */
export default {
  loading: 'ലോഡിംഗ്...',
  'no-update': 'അപ്ഡേറ്റ് ലഭ്യമല്ല',
  home: {
    tab: {
      home: 'ഹോം',
      result: 'ഫലം',
      casino: 'കാസിനോ',
      scratch: 'സ്ക്രാച്ച്',
      referral: 'റഫറൽ',
      me: 'ഞാൻ',
      live: 'ലൈവ്',
      liveCasino: 'ലൈവ് കാസിനോ',
      lottery: 'ലോട്ടറി',
    },
    scratch: {
      free: 'സൗജന്യം',
    },
    kerala: {
      title: 'കേരള സംസ്ഥാന ഭാഗ്യക്കുറി',
      no: 'ഇല്ല.',
    },
    digit: {
      title: '3 ഡിജിറ്റ് ഗെയിം',
      booking: 'ബുക്കിംഗ്',
      ticket: 'ടിക്കറ്റ്',
    },
    dice: {
      title: 'ഡൈസ് ഗെയിം',
      play: ' കളിക്കുക',
    },
    color: {
      title: 'കളർ പ്രെഡിക്ഷൻ',
    },
    matka: {
      bid: 'ബെറ്റ്',
      win: 'വിജയിച്ചു',
      open: 'തുറക്കുക',
      close: 'അടയ്ക്കുക',
      title: 'സട്ട മഡ്ക്ക',
    },
    'live-casino': {
      title: 'ലൈവ് കാസിനോ',
    },
    tip: {
      closed:
        '{{name}} ഇപ്പോൾ അടച്ചിരിക്കുന്നു, ദയവായി അടുത്ത ഗെയിം തിരഞ്ഞെടുക്കുക',
      copied: ' പകർത്തുക',
    },
    label: {
      menu: 'മെനു',
      viewAll: 'എല്ലാം കാണുക',
      buyNow: 'ഇപ്പോൾ വാങ്ങുക',
      'lucky-spin': 'ലക്കി സ്പിൻ',
      rebate: 'ബെറ്റ് കെട്ടുക',
      'free-lottery': 'ഫ്രീ ലോട്ടറി',
      referral: 'റഫറൽ',
      agent: 'ഏജന്റ്',
      promotion: 'പ്രമോഷൻ',
    },
    luckyspin: {
      add: 'ചേർക്കുക',
      spin: 'സ്പിൻ',
      won: 'വിജയിച്ചു',
      winner: 'വിജയികൾ',
      mySpin: 'എൻ്റെ സ്പിൻ',
    },
  },
  me: {
    webview: {
      vip: 'പ്രധാന അംഗം',
    },
    new: 'പുതിയത്',
    user: {
      userId: 'ഉബയോക്താവിൻ്റെ ഐഡി',
      moreExciting: 'ലോഗിൻ ചെയ്തതിന് ശേഷം കൂടുതൽ വിവരങ്ങൾ',
      login: 'ലോഗിൻ ചെയ്യുക',
      loginUpper: 'ലോഗിൻ',
    },
    money: {
      totalWallet: 'ആകെ വാലറ്റ്',
      cashBalance: 'ബാക്കി പണം',
      withdrawAmount: 'പിൻവലിക്കാവുന്ന തുക',
    },
    vip: {
      recharge: 'റീചാർജ്',
      move: 'തുടരുക',
    },
    bottom: {
      recharge: 'റീചാർജ്',
      withdraw: 'പിൻവലിക്കുക',
      transfer: 'ട്രാൻസ്‌ഫർ',
      proxy: 'ഏജൻസി സെൻറ്റർ',
      logout: 'ലോഗ് ഔട്ട്',
      resultHistory: 'റിസൾട്ട് ഹിസ്‌റ്ററി',
      myBets: 'എൻ്റെ ബെറ്റുകൾ',
      myTransactions: 'ഇടപാടുകൾ',
      commission: 'കമ്മീഷൻ',
      notify: 'അറിയിപ്പുകൾ',
      lang: 'ഭാഷകൾ',
      customer: 'ഉപഭോക്‌താവ്‌',
      update: 'അപ്ഡേറ്റ് ചെയ്യുക',
      password: 'പാസ്സ്‌വേർഡ്',
    },
    language: {
      switch: 'ഭാഷ മാറ്റുക',
    },
    tip: {
      cashTitle: 'ബാക്കി പണം:',
      cashContent:
        '"ക്യാഷ് ബാലൻസ്" എന്നത് നിങ്ങളുടെ അക്കൗണ്ടിൽ ലഭ്യമായ യഥാർത്ഥ പണത്തെയാണ്   സൂചിപ്പിക്കുന്നത്, അത് വാതുവെയ്‌ക്കോ മറ്റ് ഇടപാടുകൾക്കോ ​​ഉപയോഗിക്കാം.',
      withdrawTitle: 'പിൻവലിക്കാവുന്ന തുക:',
      withdrawContent:
        '"പിൻവലിക്കാവുന്ന തുക" എന്നത് നിങ്ങളുടെ അക്കൗണ്ടിൽ നിന്ന് പിൻവലിക്കാൻ കഴിയുന്ന നിങ്ങളുടെ ക്യാഷ് ബാലൻസിന്റെ ഭാഗമാണ്. ഫണ്ടുകൾ പിൻവലിക്കുന്നതിന് മുമ്പ് പൂർത്തീകരിക്കേണ്ട വാജറിംഗ് ആവശ്യകതകൾ നിറവേറ്റിയ പണത്തെ ഇത് പ്രതിനിധീകരിക്കുന്നു.',
    },
  },
  invitation: {
    title: {
      singUp: 'സൈൻ അപ്പ് ചെയ്യുക',
    },
    label: {
      claim: 'നിങ്ങളുടെ ടിക്കറ്റ് ക്ലെയിം ചെയ്യുക',
    },
  },
  article: {
    detail: {
      title: 'ലേഖനത്തിൻ്റെ വിശദാംശം',
    },
  },
};
